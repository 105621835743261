import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		component: () => import("../views/Index.vue"),
		children: [
			{
				name: "Home",
				path: "",
				component: () => import("../views/Home.vue"),
			},
		],
	},
	{
		path: "/list",
		component: () => import("../views/Index.vue"),
		children: [
			{
				name: "List",
				path: "",
				component: () => import("../views/List.vue"),
			},
		],
	},
	{
		path: "/mypage",
		component: () => import("../views/Index.vue"),
		children: [
			{
				name: "Mypage",
				path: "",
				component: () => import("../views/Mypage.vue"),
			},
		],
	},
	{
		path: "/place/:id*",
		component: () => import("../views/Index.vue"),
		props: true,
		children: [
			{
				name: "Place",
				path: "",
				component: () => import("../views/Place.vue"),
			},
		],
	},
	{
		path: "/details_graph/:id*",
		component: () => import("../views/Index.vue"),
		props: true,
		children: [
			{
				name: "DetailsGraph",
				path: "",
				component: () => import("../views/DetailsGraph.vue"),
			},
		],
	},
	{
		path: "/forecast_graph/:id*",
		component: () => import("../views/Index.vue"),
		props: true,
		children: [
			{
				name: "ForecastGraph",
				path: "",
				component: () => import("../views/ForecastGraph.vue"),
			},
		],
	},
	{
		path: "/evaluate_graph/:id*",
		component: () => import("../views/Index.vue"),
		props: true,
		children: [
			{
				name: "EvaluateGraph",
				path: "",
				component: () => import("../views/EvaluateGraph.vue"),
			},
		],
	},
	{
		path: "/admin/home",
		component: () => import("../views/Index.vue"),
		children: [
			{
				name: "AdminHome",
				path: "",
				component: () => import("../views/AdminHome.vue"),
			},
		],
	},
	{
		path: "/error",
		name: "Error",
		component: () => import("../views/Error.vue"),
	},
	{
		path: "*",
		redirect: "/",
	},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
