import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		summary: [
			{
				id: '001',
				name: '愛子ため池',
				district: '青葉区',
				waterlevel: 3.3,
				unit: 'm',
				alertThresholds: [
					0.4,
					1.4,
					2.4,
					3.7
				],
				position: {
					lat: 38.26472805452458,
					lng: 140.7361315730636
				}
			},
			{
				id: '002',
				name: '斉勝沼溜池',
				district: '青葉区',
				waterlevel: 0.3,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					5.0
				],
				position: {
					lat: 38.2655230215736,
					lng: 140.73730250219248
				}
			},
			{
				id: '003',
				name: '志田堤溜池',
				district: '青葉区',
				waterlevel: 2.1,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					4.0
				],
				position: {
					lat: 38.28259622178136,
					lng: 140.6921891952205
				}
			},
			{
				id: '004',
				name: '青野木溜池',
				district: '青葉区',
				waterlevel: 0.5,
				unit: 'm',
				alertThresholds: [
					1.4,
					1.9,
					2.4,
					3.6
				],
				position: {
					lat: 38.303674924527165,
					lng: 140.72147387189315
				}
			},
			{
				id: '005',
				name: '鶴ケ谷大堤溜池',
				district: '宮城野区',
				waterlevel: 2.7,
				unit: 'm',
				alertThresholds: [
					0.4,
					0.8,
					1.6,
					2.6
				],
				position: {
					lat: 38.293044766678435,
					lng: 140.91942931800438
				}

			},
			{
				id: '006',
				name: '安養寺上溜池',
				district: '宮城野区',
				waterlevel: 2.7,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					4.2
				],
				position: {
					lat: 38.287107760724936,
					lng: 140.90457682412728
				}
			},
			{
				id: '007',
				name: '安養寺下溜池',
				district: '宮城野区',
				waterlevel: 3.6,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					4.0
				],
				position: {
					lat: 38.28534022257258,
					lng: 140.90718114908407
				}
			},
			{
				id: '008',
				name: '入生沢中溜池',
				district: '宮城野区',
				waterlevel: 0.2,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					5.0
				],
				position: {
					lat: 38.31298255318782,
					lng: 140.93249448101847
				}
			},
			{
				id: '009',
				name: '入生沢下溜池',
				district: '宮城野区',
				waterlevel: 0.6,
				unit: 'm',
				alertThresholds: [
					2.4,
					4.7,
					6.4,
					7.0
				],
				position: {
					lat: 38.31412491179131,
					lng: 140.93634227748097
				}
			},
			{
				id: '010',
				name: '西沢上溜池',
				district: '宮城野区',
				waterlevel: 1.3,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					4.0
				],
				position: {
					lat: 38.31867690857847,
					lng: 140.93042051105868
				}
			},
			{
				id: '011',
				name: '西沢下溜池',
				district: '宮城野区',
				waterlevel: 1.9,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					4.6
				],
				position: {
					lat: 38.311369113613495,
					lng: 140.93493863428012
				}
			},
			{
				id: '012',
				name: '中溜池',
				district: '太白区',
				waterlevel: 2.3,
				unit: 'm',
				alertThresholds: [
					0.4,
					1.7,
					2.4,
					5.0
				],
				position: {
					lat: 38.2239268874634,
					lng: 140.82769342980262
				}
			},
			{
				id: '013',
				name: '街道溜池',
				district: '太白区',
				waterlevel: 1.8,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					4.2
				],
				position: {
					lat: 38.22338733075164,
					lng: 140.83048299324372
				}
			},
			{
				id: '014',
				name: '西ノ堤溜池',
				district: '泉区',
				waterlevel: 2.3,
				unit: 'm',
				alertThresholds: [
					0.4,
					0.8,
					1.5,
					2.2
				],
				position: {
					lat: 38.32974518955237,
					lng: 140.87046795618988
				}
			},
			{
				id: '015',
				name: '住吉台5号溜池',
				district: '泉区',
				waterlevel: 2.8,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					4.4,
					5.0
				],
				position: {
					lat: 38.3262351564318,
					lng: 140.7812244044803
				}
			},
			{
				id: '016',
				name: '新釜の沢溜池',
				district: '泉区',
				waterlevel: 0.1,
				unit: 'm',
				alertThresholds: [
					1.4,
					2.7,
					3.4,
					5.0
				],
				position: {
					lat: 38.31438506345502,
					lng: 140.80025472722235
				}
			}
		],
		iconColors: ["#9dc4e6", "#ffc001", "#f30016", "#bc0cae", "#000000"],
		waterlevelData: {},
		powerlevelData: {},
		waterlevelForecastData: {},
		waterlevelForecastTickData: {},
		waterlevelEvaluateData: {},
	},
	getters: {
		getPlaceById: state => id => {
			return state.summary.find(item => item.id === id)
		},
		getPlaceMarkers: state => {
			var s = state.summary.map(item => {
				let obj = {};
				obj['title'] = item.name;
				obj['position'] = item.position;
				return obj;
			})
			return s;
		},
		getAlertColor: state => item => {
			let alertColorIndex = 0;
			for (var i = 0; i < item.alertThresholds.length; i++) {
				if (item.waterlevel > item.alertThresholds[i]) {
					alertColorIndex = i + 1;
				}
			}
			return state.iconColors[alertColorIndex];
		},
	},
	actions: {
		setWaterlevelData({ state }, { index }) {
			if (state.waterlevelData.length == 0 || !state.waterlevelData[index]) {
				let item = state.summary.find(item => item.id === index);
				let now = new Date();
				let prev = new Date();
				let data = [["日時", "水位", "常時満水位", "降雨量"]];
				let current = item.waterlevel;
				let count = 0;
				let rand = Math.round(Math.random() * 5) + 5;
				let rainfall = Math.random() * 500 - 100;
				rainfall = Math.max(rainfall, 0);
				for (prev = new Date(prev.setHours(prev.getHours() - 24 * 2)); prev < now; prev = new Date(prev.setMinutes(prev.getMinutes() + 60))) {
					if (count % rand == 0) {
						rainfall = Math.random() * 500 - 100;
						rainfall = Math.max(rainfall, 0);
						rand = Math.round(Math.random() * 5) + 5;
					}
					current = item.waterlevel + Math.random() * 3 - 2;
					current = Math.max(current, 0);
					data.push([prev, current, item.alertThresholds[2], rainfall]);
					count++;
				}
				data.push([now, item.waterlevel, item.alertThresholds[2], rainfall]);
				state.waterlevelData[index] = data;
			}
		},
		setPowerlevelData({ state }, { index }) {
			if (state.powerlevelData.length == 0 || !state.powerlevelData[index]) {
				state.powerlevelData[index] = [
					["DateTime", "電圧"],
					[new Date("2020/10/01 12:34:56"), 2.5],
					[new Date("2020/10/01 12:44:56"), 2.5],
					[new Date("2020/10/01 12:54:56"), 2.5],
					[new Date("2020/10/01 13:04:56"), 2.5],
					[new Date("2020/10/01 13:14:56"), 2.5],
					[new Date("2020/10/01 13:24:56"), 2.5],
					[new Date("2020/10/01 13:34:56"), 2.5],
					[new Date("2020/10/01 13:44:56"), 2.5],
					[new Date("2020/10/01 13:54:56"), 2.5],
					[new Date("2020/10/01 14:04:56"), 2.5],
				];
			}
		},
		setWaterlevelForecastData({ state }, { index }) {
			if (state.waterlevelForecastData.length == 0 || !state.waterlevelForecastData[index]) {
				let prev = new Date();
				prev = new Date(prev.setHours(prev.getHours() - 24 * 15));
				let tickData = [];
				tickData.push({v: prev, f: "貯水開始 (" + dateFormat.format(prev, "MM月dd日 hh時") + ")"});
				let data = [[{label: '日時', type: 'date'}, 
					{label: '常時満水位', type: 'number'}, 
					{label: '事前放流の判断時点', type: 'number'}, 
					{label: '11日後', type: 'number'},
					{label: '観測水位', type: 'number'}, 
					{label: '目標貯水位', type: 'number'}, 
					{label: '事前放流水位', type: 'number'}, 
					{label: '予測回復水位 (中央値)', type: 'number'}, 
					{label: '予測回復水位 (下限値)', type: 'number'}, 
					{label: '予測回復水位 (上限値)', type: 'number'}, 
				]];
				let waterlevel = 3.5;
				let s = 1.0;
				for (var i = 0; i < 20; i++) {
					data.push([prev, 6, null, null, waterlevel, 2, null, null, null, null]);
					prev = new Date(prev.setMinutes(prev.getMinutes() + 360));
					s -= 0.04;
					waterlevel += 0.2 * s;
				}
				for (i = 0; i < 20; i++) {
					data.push([prev, 6, null, null, waterlevel, 2, null, null, null, null]);
					prev = new Date(prev.setMinutes(prev.getMinutes() + 360));
					s += 0.03;
					waterlevel -= 0.1 * s;
				}
				// 事前放流の判断時点
				data.push([prev, 6, 8, null, waterlevel, 2, null, null, null, null]);
				data.push([prev, 6, 0, null, waterlevel, 2, null, null, null, null]);
				data.push([prev, 6, null, null, waterlevel, 2, waterlevel, null, null, null]);
				data.push([prev, 6, null, null, waterlevel, 2, 3.5, null, null, null]);
				tickData.push({v: prev, f: "事前放流の判断時点 (" + dateFormat.format(prev, "MM月dd日 hh時") + ")"});
				let main = 3.5;
				let sub1 = 3.5;
				let sub2 = 3.5;
				for (i = 0; i < 43; i++) {
					data.push([prev, 6, null, null, null, 2, null, main, sub1, sub2]);
					prev = new Date(prev.setMinutes(prev.getMinutes() + 360));
					main += 0.0349;
					sub1 += 0.0249;
					sub2 += 0.0449;
				}
				// 11日後
				data.push([prev, 6, null, 8, null, 2, null, main, sub1, sub2]);
				data.push([prev, 6, null, 0, null, 2, null, main, sub1, sub2]);
				prev = new Date(prev.setMinutes(prev.getMinutes() + 360));
				tickData.push({v: prev, f: "11日後 (" + dateFormat.format(prev, "MM月dd日 hh時") + ")"});
				for (i = 0; i < 40; i++) {
					data.push([prev, 6, null, null, null, 2, null, null, null, null]);
					prev = new Date(prev.setMinutes(prev.getMinutes() + 360));
				}
				tickData.push({v: prev, f: "かんがい終了 (" + dateFormat.format(prev, "MM月dd日 hh時") + ")"});
				state.waterlevelForecastData[index] = data;
				state.waterlevelForecastTickData["tick"] = tickData;
			}
		},
		setWaterlevelEvaluateData({ state }, { index }) {
			if (state.waterlevelEvaluateData.length == 0 || !state.waterlevelEvaluateData[index]) {
				let item = state.summary.find(item => item.id === index);
				let now = new Date();
				let prev = new Date();
				let data = [["日時", "水位"]];
				let current = item.waterlevel;
				for (prev = new Date(prev.setHours(prev.getHours() - 24 * 2)); prev < now; prev = new Date(prev.setMinutes(prev.getMinutes() + 60))) {
					current = item.waterlevel + Math.random() * 3 - 2;
					current = Math.max(current, 0);
					data.push([prev, current]);
				}
				data.push([now, item.waterlevel]);
				state.waterlevelEvaluateData[index] = data;
			}
		},	},
	mutations: {
	},
	modules: {
	}
})

// 日付をフォーマットする
const dateFormat = {
    _fmt: {
        hh: function (date) { return ('0' + date.getHours()).slice(-2); },
        h: function (date) { return date.getHours(); },
        mm: function (date) { return ('0' + date.getMinutes()).slice(-2); },
        m: function (date) { return date.getMinutes(); },
        ss: function (date) { return ('0' + date.getSeconds()).slice(-2); },
        dd: function (date) { return ('0' + date.getDate()).slice(-2); },
        d: function (date) { return date.getDate(); },
        s: function (date) { return date.getSeconds(); },
        yyyy: function (date) { return date.getFullYear() + ''; },
        yy: function (date) { return date.getYear() + ''; },
        t: function (date) { return date.getDate() <= 3 ? ["st", "nd", "rd"][date.getDate() - 1] : 'th'; },
        w: function (date) { return ["Sun", "$on", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]; },
        MMMM: function (date) { return ["January", "February", "$arch", "April", "$ay", "June", "July", "August", "September", "October", "November", "December"][date.getMonth()]; },
        MMM: function (date) { return ["Jan", "Feb", "$ar", "Apr", "$ay", "Jun", "Jly", "Aug", "Spt", "Oct", "Nov", "Dec"][date.getMonth()]; },
        MM: function (date) { return ('0' + (date.getMonth() + 1)).slice(-2); },
        M: function (date) { return date.getMonth() + 1; },
        $: function () { return 'M'; }
    },
    _priority: ["hh", "h", "mm", "m", "ss", "dd", "d", "s", "yyyy", "yy", "t", "w", "MMMM", "MMM", "MM", "M", "$"],
    format: function (date, format) { return this._priority.reduce((res, fmt) => res.replace(fmt, this._fmt[fmt](date)), format) }
}
